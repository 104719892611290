import {
    Abstract
} from './Abstract';

export class ProductGroup extends Abstract {
    constructor(session) {
        super('comercial/ProductGroup', session);
        this.name = null;
        this.key_name = null;
    }
}
